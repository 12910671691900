/* Theme */
	.dark {
		--bg: #222;
		--window: #333;
		--inset: #555;
		--text: #fff;
		--shade: #0003;
		--tooltip: #aaa;
	}

	.light {
		--bg: #fff;
		--window: #eee;
		--inset: #aaa;
		--text: #000;
		--shade: #0003;
		--tooltip: #333;
	}

/* Document */
	body {
		width: calc(100vw - 20px);
		height: calc(100vh - 20px);
		margin: 0;
		padding: 10px;
		background: var(--bg);
		color: var(--text);
		font: 15px sans-serif;
	}

	#root {
		width: inherit;
		height: inherit;
	}

	.App {
		width: inherit;
		height: inherit;
		display: grid;
		grid-template-rows: 3rem 1fr 3rem;
		grid-template-columns: 2.5fr 1fr;
		grid-template-areas:
			"head head"
			"craft inventory"
			"foot foot";
		gap: 10px;
	}

	#theme {
		height: 30px;
		width: 30px;
		font-size: 30px;
		padding: 10px;
		border-radius: 100%;
		position: fixed;
		bottom: 20px;
		right: 20px;
		background: var(--window);
		line-height: 1;
		transition: all .2s;
		z-index: 99;
	}

		#theme:where(:hover, :focus) {
			background: var(--bg);
			box-shadow: none;
			bottom: 15px;
			right: 15px;
		}

	* {
		transition: .2s all;
	}

/* Globals */
	.window {
		background: var(--window);
		border-radius: 10px;
		box-shadow:	5px 5px 0 var(--shade);
	}

	.inset {
		background: var(--inset);
		border-radius: 10px;
		box-shadow: inset 5px 5px 0 var(--shade);
	}

	.noscript {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 99;
		padding: 1em 1ch;
	}

/* Header */
	header {
		grid-area: head;
		position: relative;
	}

	h1 {
		margin: auto;
		font-size: 30px;
		text-align: center;
		position: absolute;
		width: 100%;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

/* Crafting Interface */
	main {
		position: relative;
		grid-area: craft;
	}

	.CraftingInterface {
		--size: 20vmin;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: fit-content;
		min-width: 33%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		padding: 10px;
		max-height: calc(100% - 20px);
	}

	.input,
	.output {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 5px;
	}

	.buttons {
		display: flex;
		justify-content: space-around;
		width: 100%;
	}

	button {
		padding: 5px;
		border: none;
		background: var(--bg);
		color: var(--text);
		border-radius: 5px;
		box-shadow: 2px 2px 0 var(--shade);
		position: relative;
		top: 0;
		left: 0;
	}

		button:where(:hover, :focus, :active) {
			background: var(--tooltip);
			color: var(--bg);
			box-shadow: none;
			top: 2px;
			left: 2px;
		}

	.CraftingInterface svg {
		color: var(--inset);
	}

	.CraftedItemName{
		display: inline-block;
		width: calc(var(--size) - 10px);
		height: calc(1em);
		padding: 5px;
		text-align: center;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

/* Crafting Grid */
	.CraftingGrid {
		display: grid;
		grid-template: repeat(3, 1fr)/repeat(3, 1fr);
		gap: 5px;
		width: fit-content;
		aspect-ratio: 1/1;
	}

	.GridItem {
		width: var(--size);
		height: var(--size);
	}

	.GridItem .icon {
		width: calc(var(--size) - 10px);
		height: calc(var(--size) - 10px);
	}

	.GridItem.loading {
		animation: pulse 1s infinite;
	}
/* Inventory */
	aside {
		grid-area: inventory;
		padding: 5px;
		height: calc(100vh - 8rem - 20px);
	}

	.inventory {
		grid-area: inventory;
		height: calc(100vh - 8rem - 20px);
		padding: 10px;
		overflow: scroll;
		display: flex;
		flex-wrap: wrap;
		gap: 10px;
		justify-content: space-around;
		box-shadow:
			inset 5px 5px 0 var(--shade),
			0 0 0 5px var(--window),
			5px 5px 0 5px var(--shade);
		margin: 5px;
		}

	.InventoryItem {
		--size: 100px;
		width: calc(var(--size) + 10px);
		height: calc(var(--size) + 2em);
		display: flex;
		flex-direction: column;
		background: var(--window);
		padding: 5px;
		border-radius: 10px;
		box-shadow: 5px 5px 0 var(--shade);
		position: relative;
		top: 0;
		left: 0;
	}

		.InventoryItem:where(:hover, :focus, .selected) {
			background: var(--bg);
			box-shadow: none;
			top: 5px;
			left: 5px;
		}

	.icon {
		width: var(--size);
		height: var(--size);
		font-size: calc(var(--size) / 3);
		/*text-shadow: 5px 5px 0 var(--shade);*/
		display: grid;
		grid-template: repeat(2, 1fr) / repeat(2,1fr);
		padding: 5px;
		text-align: center;
	}

	.icon img {
		filter: drop-shadow(5px 5px 0 var(--shade));
	}

	.InventoryItem:where(:hover, :focus, .selected) .icon {
		background: var(--shade);
		box-shadow: none;
		text-shadow: none;
	}

	.InventoryItem .namebox {
		width: 100%;
		text-align: center;
		overflow: hidden;
		white-space: nowrap;
		position: relative;
	}

	.InventoryItem .namebox .name {
		display: block;
		transform: translateX(0);
		transition: transform 3s ease-in-out;
		width: fit-content;
		margin: auto;
	}

		.InventoryItem:where(:hover, :focus, .selected) .namebox .name {
			/* transform: translateX(calc(var(--size) + 10px - 100%)); */ /* TODO: Make short names not scroll */
		}

	.InventoryItem .tooltip {
		position: absolute;
		display: block;
		background: var(--tooltip);
		color: var(--window);
		top: calc(100% + 10px);
		left: 50%;
		transform: translateX(-50%);
		text-align: center;
		padding: 5px;
		border-radius: 5px;
		opacity: 0;
		z-index: 9;
		pointer-events: none;
		filter: drop-shadow(5px 5px 0 var(--shade));
	}

	.InventoryItem:where(:hover, :focus) .tooltip {
		opacity: 1;
	}

	.InventoryItem .tooltip::before {
		content: "";
		display: block;
		height: 0;
		width: 0;
		border: 10px solid;
		border-color: transparent transparent var(--tooltip) transparent;
		position: absolute;
		left: 50%;
		top: -18px;
		transform: translateX(-50%);
	}

/* Currently selected item */
	.CurrentlySelectedItem {
		--size: 60px;
		position: absolute;
		width: fit-content;
		height: fit-content;
		padding: 5px;
		border-radius: 10px;
		transition: unset;
		pointer-events: none;
	}

		.CurrentlySelectedItem .icon {
		width: var(--size);
		height: var(--size);
	}

/* Emoji */
.twemoji > img {
	height: 1em;
	width: auto;
	display: inline-block;
}

/* Footer */
	footer {
		grid-area: foot;
	}

/* Responsiveness */
	@media screen and (max-width: 1024px){
		.CraftingInterface {
			flex-direction: column;
		}

		.CraftingInterface > svg {
			transform: rotateZ(90deg);
		}
	}

	@media screen and (max-width: 600px){
		.App {
			grid-template-rows: 3rem 50vh 1fr 3rem;
			grid-template-columns: 1fr;
			grid-template-areas:
				"head"
				"craft"
				"inventory"
				"foot";
		}

		h1 {
			font-size: 1.5em;
		}


		.CraftedItemName {
			width: 40vw;
		}

		aside {
			height: calc(50vh - 6rem);
		}

		.InventoryItem {
			--size: 75px;
		}
	}

	@media (hover: none) {
		.CurrentlySelectedItem {
			display: none;
		}
	}

/* Animations */

	@keyframes pulse {
		0% {
			box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
		}
		100% {
			box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
		}
		}